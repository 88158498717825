import {
  Container,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Header from "../components/Header";
import images from "../images/images";
import Card from "../components/Card";
import Firstcard from "../components/Firstcard";
import Trusted from "../components/Trusted";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function Homepage() {
  const useStyles = makeStyles({
    heroSection: {
      width: "100%",
      height: "auto",
      overflow: "hidden",
    },
    content: {
      position: "relative",
      width: "120%",
      left: "-10%",
      height: "auto",
      overflow: "hidden",
      borderRadius: "0 0 50% 50% / 150px",
      backgroundColor: "#fff3e4",
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: "50%",
        width: "100%",
        height: "auto",
        transformOrigin: "bottom",
        transform: "translatex(-50%) scale(4)",
      },
    },
  });

  const tablet = useMediaQuery("(max-width:900px)");
  const desktop = useMediaQuery("(max-width:1200px)");

  const classes = useStyles();
  return (
    <>
      <div className={classes.heroSection}>
        <div className={classes.content}>
          <div style={{ width: "100%" }}>
            <Header />
            <Container>
              <Grid
                container
                spacing={3}
                style={{
                  marginTop: tablet ? "100px" : "50px",
                  padding: tablet ? "0 8%" : desktop ? "0 8%" : null,
                }}
              >
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div>
                    <Typography
                      variant="h3"
                      color="primary"
                      style={{ paddingBottom: "30px", fontSize: "54px" }}
                      fontWeight={600}
                    >
                      Build{" "}
                      <Typography
                        variant="h3"
                        style={{ fontSize: "54px" }}
                        fontWeight={600}
                        component="span"
                        color="secondary"
                      >
                        with Harvoxx
                      </Typography>
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "400" }}>
                      Enjoy custom software development by our experienced and
                      software engineers. Frontend, backend, database and server
                      solutions for mobile apps, web applications and desktop.
                    </Typography>
                    <Link to="/build" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          color: "#fff",
                          margin: "30px 0 0 0",
                          padding: "10px 40px",
                          borderRadius: "100px",
                          textDecoraton: "none",
                        }}
                      >
                        build now
                      </Button>
                    </Link>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <img
                    src={images.landingImg}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <Container>
        <Grid
          container
          alignItems="flex-start"
          spacing={4}
          style={{ height: "auto", marginTop: "60px" }}
        >
          <Grid item md={6} xs={12}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight="600"
              align="left"
              style={{ paddingBottom: "15px" }}
            >
              Build With
              <span style={{ color: "#DCA424" }}> Harvoxx</span>
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "#696984",
                paddingBottom: "40px",
                paddingTop: "20px",
              }}
            >
              We have all the necessary structure and resources to build and
              deploy quality and innovative products to clients. As a brand we
              help you bring your ideas to reality by leveraging the power of
              technology.
            </Typography>
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "60px",
              }}
            >
              <Link to="/build" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ borderRadius: "100px", padding: "10px 30px" }}
                >
                  build with us
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item container spacing={6} md={6} xs={12}>
            <Grid item md={6} sm={6} xs={12}>
              <Firstcard
                images={images.team}
                header="Strong Team"
                text="We’ve got a strong team of developers"
                color="#23bdee"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Firstcard
                images={images.technology}
                header="Efficient Technologies"
                text="We use the best and latest technologies to build for you"
                color="#f3617b"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Firstcard
                images={images.time}
                header="Timely Delivery"
                text="We set and beat timelines for every stage of development "
                color="#ff561c"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <Firstcard
                images={images.communication}
                header="Effective Communication"
                text="We communicate with every client consistently and clearly, throughout every stage of development "
                color="#41b883"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight="600"
          align="center"
          style={{ marginTop: "130px", paddingBottom: "15px" }}
        >
          Guaranteed <span style={{ color: "#DCA424" }}> Happiness</span> in 4
          steps
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{
            color: "#696984",
            textAlign: "center",
            paddingBottom: "70px",
          }}
        >
          We have a four-step development process: We Listen, We Build, We
          Deploy and You‘re Happy.
        </Typography>
        <Grid
          container
          spacing={tablet ? 7 : 4}
          style={{ marginBottom: "50px" }}
        >
          <Grid item md={3} sm={6} xs={12}>
            <Card
              image={images.listen}
              header="We Listen"
              text="First, we pay attention to understand what you want to build."
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Card
              image={images.build}
              header="We Build"
              text="Then we set up a team of developers to build, bringing your dream to life."
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Card
              image={images.deploy}
              header="We Deploy"
              text="After building and testing, we will deploy an efficiently working product to you."
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Card
              image={images.happy}
              header="You're Happy"
              text="Now, your product is live; You are HAPPY."
              text2="That's why we are trusted."
            />
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            marginBottom: "60px",
          }}
        >
          <Link to="/build" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ borderRadius: "100px", padding: "10px 30px" }}
            >
              build with us
            </Button>
          </Link>
        </div>
      </Container>
      <Container>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight="600"
          align="center"
          style={{
            marginTop: "130px",
            paddingBottom: tablet ? "50px" : "80px",
          }}
        >
          Why we are trusted
        </Typography>
        <Trusted
          direction={tablet ? "column-reverse" : "row"}
          blue="Source Code  "
          orange="Is All Yours"
          text="We build it, but you own it. Yes, it’s all yours, once we are done developing and deploying your product, we hand over the complete source to you. So, you have 100% ownership and control."
          image={images.trust1}
          display1="none"
          display2="none"
          fit="contain"
        />
        <Trusted
          direction={tablet ? "column-reverse" : "row-reverse"}
          blue="We Stick With You "
          orange="All The Way"
          text="If you decide to continue working with us for maintenance and updates, or take the project internal, we will be more than happy to serve you. We will follow all the way, because we believe that the success of your product is our success."
          image={images.trust2}
          display1="none"
          display2="none"
          fit="contain"
        />
        <Trusted
          direction={tablet ? "column-reverse" : "row"}
          blue="What Happens In Vegas,  "
          orange="Stays In Vegas"
          text="We do not allow intruders or third parties; we keep everything in-house. Every member of the Build-With-Harvoxx team and our product managers and everyone connected in between are all our own verified and trained team member. They understand the rule and they play by the rule.  That is why, we deliver quality to our clients and on time too.
          "
          image={images.trust3}
          display1="none"
          display2="none"
          fit="contain"
        />
        <Trusted
          direction={tablet ? "column-reverse" : "row-reverse"}
          blue="We Are Right  "
          orange="Down The Road"
          text="To us, Face-to-Face problem solving, plays a key role in getting a successful project. We know you trust us, so we want to be more than a name on your phone screen. We want you to know where we are and to meet our team. By being your next-door neighbor, we know you can hold us accountable. If you are not so close, we explore technological tools to help bring you so close to us while we work on your project."
          image={images.trust4}
          display1="none"
          display2="none"
          fit="contain"
        />
        <Trusted
          direction={tablet ? "column-reverse" : "row"}
          blue="Transparency And  "
          orange="Commitment"
          text="We have a reputation to protect. We have built a long-time collaboration and we want to earn your trust and keep it. We earn and sustain your trust through our commitment to absolute transparency. We stay as an open book about us, our processes, our work, and your project. We will consistently provide you updates on the status of your project, budget, timelines etc. Constant updates will be at your disposal."
          image={images.trust5}
          display1="none"
          display2="none"
          fit="contain"
        />
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            marginBottom: "60px",
          }}
        >
          <Link to="/build" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ borderRadius: "100px", padding: "10px 30px" }}
            >
              build with us
            </Button>
          </Link>
        </div>
      </Container>
      <Container>
        <Grid container spacing={5} style={{ marginBottom: "130px" }}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                color: "#696984",
                textAlign: "center",
                paddingTop: "60px",
              }}
            >
              Trusted Companies that have used us
            </Typography>
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.reelwood}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.climateSolution}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.correctleg}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.designversity}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.dgsl}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.hackify}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.jobpadi}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.leEldera}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.luckyWin}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.ogabike}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.ogapredictor}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.quickPower}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            md={2}
            sm={4}
            xs={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={images.techlauncher}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight="600"
          align="center"
          style={{
            marginTop: "130px",
            paddingBottom: tablet ? "50px" : "80px",
          }}
        >
          Tools we use
        </Typography>
        <Grid container spacing={6} style={{ marginBottom: "150px" }}>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.react}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.node}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.aws}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.firebase}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.php}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.css}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.html}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.python}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.adobeXd}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.figma}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.github}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.flutter}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.vue}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <img
              src={images.angular}
              alt=""
              style={{ width: "100%", height: "110px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div
              style={{
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                // marginBottom: "60px",
              }}
            >
              <Link to="/build" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ borderRadius: "100px", padding: "10px 30px" }}
                >
                  build with us
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Homepage;
