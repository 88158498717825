import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Call from "./Call";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  logoShape: {
    width: "35px",
    height: "35px",
    backgroundColor: "#DCA424",
    transform: "rotate(49deg)",
    position: "relative",
    cursor:"pointer"
  },
});

function Header() {
  const classes = useStyles();
  const tablet = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(false);
  const [call, setCall] = useState(false);
  const [background, setBackground] = useState({
    color: "transparent",
    shadow: null,
  });
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const scrollEffect = () => {
    // console.log(e);
    // console.log("window", window);
    if (window.scrollY > 1) {
      setBackground({ color: "#fff", shadow: "0 0 25px rgba(0,0,0,0.1)" });
    } else {
      setBackground({
        background: "transparent",
        shadow: null,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEffect);
    return () => {
      window.removeEventListener("scroll", scrollEffect);
    };
  }, []);

  const history = useHistory();

  return (
    <>
      <div
        style={{
          position: "fixed",
          left: "0%",
          top: "0px",
          width: "100%",
          backgroundColor: background.color,
          boxShadow: background.shadow,
          zIndex: 5,
          transition: ".2s ease-in",
        }}
      >
        <Container>
          <Grid container>
            <Grid
              item
              xs={10}
              sm={7}
              style={{
                height: "80px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative",
                
              }}
            >
              <div className={classes.logoShape} onClick={() => history.push("/")}></div>
              <Typography
                variant="h5"
                color="secondary"
                style={{
                  position: "absolute",
                  left: "15px",
                  cursor:"pointer"
                }}
                fontWeight={600}
                onClick={() => history.push("/")}
              >
                Build with Harvoxx
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sm={5}
              style={{
                height: "80px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div style={{ display: tablet ? "none" : "block" }}>
                <button
                  style={{
                    color: "#2F327D",
                    backgroundColor: "#fff",
                    padding: "0px 30px",
                    borderRadius: "100px",
                    border: "none",
                    boxShadow: "0 0 25px rgba(0,0,0,0.1)",
                    fontFamily: "'Poppins', sans-serif",
                    cursor: "pointer",
                  }}
                  onClick={() => setCall(true)}
                >
                  <p>Let's Call You</p>
                </button>
                <button
                  style={{
                    color: "#fff",
                    backgroundColor: "#DCA424",
                    padding: "0px 30px",
                    borderRadius: "100px",
                    border: "none",
                    boxShadow: "0 0 25px rgba(0,0,0,0.1)",
                    fontFamily: "'Poppins', sans-serif",
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                  onClick={() => history.push("/build")}
                >
                  <p>Let's Build For You</p>
                </button>
              </div>
              <IconButton
                onClick={() => setOpen(true)}
                style={{ display: tablet ? "block" : "none" }}
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <div>
            <SwipeableDrawer
              anchor="left"
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
            >
              <div style={{ width: "auto", height: "60%" }}>
                <div>
                  <div
                    style={{
                      padding: "10px 10px 10px 10px",
                      height: "90px",
                      width: "250px",
                      backgroundColor: "#fff",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      position: "relative",
                    }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <div className={classes.logoShape} onClick={() => history.push("/")}></div>
                    <Typography
                      variant="h5"
                      color="secondary"
                      style={{
                        position: "absolute",
                        left: "30px",
                        cursor:"pointer"
                      }}
                      fontWeight={600}
                      onClick={() => history.push("/")}
                    >
                      Build with Harvoxx
                    </Typography>
                  </div>
                </div>
                <Divider />
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      setOpen(false);
                      setCall(true);
                    }}
                    style={{ color: "#DCA424" }}
                  >
                    <ListItemText primary={"Let's Call You"} />
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      setOpen(false);
                      history.push("/build")
                    }}
                    style={{ color: "#DCA424" }}
                  >
                    <ListItemText primary={"Let's Build For You"} />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          </div>
        </Container>
      </div>
      <Call show={call} onHide={setCall} />
    </>
  );
}

export default Header;
