import React from "react";
import { Paper, Typography } from "@mui/material";

function Card({ header, image, text, text2 }) {
  return (
    <>
      <Paper
        style={{
          boxShadow: "0 0 25px rgba(0,0,0,0.1)",
          position: "relative",
          padding: "60px 10px 10px 10px ",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <Typography
            variant="h6"
            fontWeight="600"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {header}
          </Typography>
          <Typography variant="body1" color="secondary">
            {text}
          </Typography>
          <Typography variant="body1" color="secondary">
            {text2}
          </Typography>
        </div>
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            position: "absolute",
            top: "-30px",
            // left: mobile ? "40%" : tablet ? "37%" : "39%",
            backgroundColor: "#5B72EE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <img
            src={image}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      </Paper>
    </>
  );
}

export default Card;
