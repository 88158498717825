import { Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

function Trusted({
  direction,
  children,
  blue,
  orange,
  text,
  image,
  display1,
  display2,
  fit,
}) {
  const tablet = useMediaQuery("(max-width:900px)");
  const useStyles = makeStyles({
    logoShape: {
      display: display1,
      width: "35px",
      height: "35px",
      backgroundColor: "#DCA424",
      position: "absolute",
      bottom: "52%",
      borderRadius: "50%",
      top: tablet ? "13%" : "17%",
      left: tablet ? "13%" : "10%",
    },
    logoShape2: {
      display: display2,
      width: "20px",
      height: "20px",
      backgroundColor: "#DCA424",
      position: "absolute",
      bottom: "30%",
      borderRadius: "50%",
      right: "5%",
    },
  });
    const mobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction={direction}
        spacing={mobile ? 0 : 6 }
        style={{ marginBottom: "80px" }}
      >
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div style={{ zIndex: 2 }}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight="500"
              fontSize="32px"
              align="left"
              style={{ paddingBottom: "15px" }}
            >
              {blue}
              <span style={{ color: "#DCA424" }}>{orange}</span>
            </Typography>
            <Typography
              variant="body1"
              align="left"
              style={{
                color: "#696984",
                textAlign: "left",
                padding: "20px 0",
              }}
            >
              {text}
            </Typography>
            {children}
          </div>
          <div className={classes.logoShape}></div>
          <div className={classes.logoShape2}></div>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img
            src={image}
            alt=""
            style={{ width: "100%", height: "400px", objectFit: fit }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Trusted;
