const images = {
  landingImg: require("./header-pic-min.png").default,
  climateSolution: require("./climate-solution.jpg").default,
  correctleg: require("./correctleg.jpg").default,
  designversity: require("./designversity.jpg").default,
  dgsl: require("./dgsl.jpg").default,
  hackify: require("./hackify.jpg").default,
  jobpadi: require("./jobpadi.jpg").default,
  leEldera: require("./le-eldera.jpg").default,
  luckyWin: require("./lucky-win.jpg").default,
  ogabike: require("./ogabike.jpg").default,
  ogapredictor: require("./ogapredictor.jpg").default,
  quickPower: require("./quick-power.jpg").default,
  techlauncher: require("./techlauncher.jpg").default,
  reelwood: require("./reelwood.png").default,
  deploy: require("./shuttle.png").default,
  listen: require("./ear.png").default,
  build: require("./hacker.png").default,
  happy: require("./happiness.png").default,
  trust1: require("./source-code.png").default,
  trust2: require("./fund-min.png").default,
  trust3: require("./precious.png").default,
  trust4: require("./bryan-team-background.png").default,
  trust5: require("./ephy-dami.png").default,
  react: require("./react.svg").default,
  node: require("./node.png").default,
  aws: require("./aws.png").default,
  firebase: require("./firebase.png").default,
  php: require("./php.png").default,
  css: require("./css.png").default,
  html: require("./html.png").default,
  python: require("./python.png").default,
  adobeXd: require("./adobe-xd.png").default,
  figma: require("./figma.png").default,
  github: require("./github.png").default,
  flutter: require("./flutter.png").default,
  vue: require("./vue.png").default,
  angular: require("./angular.png").default,
  callModal: require("./call-modal.png").default,
  coder: require("./coder.png").default,
  team: require("./team.png").default,
  communication: require("./chat.png").default,
  time: require("./chronometer.png").default,
  technology: require("./technology.png").default,
};

export default images;
