import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  logoShape: {
    width: "35px",
    height: "35px",
    backgroundColor: "transparent",
    borderRadius: "3px",
    border: " 3px solid #65DAFF",
    transform: "rotate(49deg)",
    position: "relative",
  },
});

function Footer() {
  const classes = useStyles();

  // var pattern = new RegExp(
  //   /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  // );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#252641",
          padding: "60px 0px 40px 0",
        }}
      >
        <Container>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={6} style={{ height: "auto" }}>
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  //   backgroundColor: "blue",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "auto",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    position: "relative",
                    width: "240px",
                  }}
                >
                  <div className={classes.logoShape}></div>
                  <Typography
                    variant="h5"
                    style={{
                      position: "absolute",
                      left: "15px",
                      color: "#fff",
                    }}
                    fontWeight={600}
                  >
                    Build with Harvoxx
                  </Typography>
                </div>
              </div>
              <Typography
                variant="body2"
                align="center"
                style={{ color: "#B2B3CF", margin: "40px 0 0 0" }}
              >
                Harvoxx Tech Hub 
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Footer;
