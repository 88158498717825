import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";
import images from "../images/images";
import NaijaStates from "naija-state-local-government";
import Call from "../components/Call";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import { Link } from "react-router-dom"

function Build() {
  const tablet = useMediaQuery("(max-width:900px)");
  const [display, setDisplay] = useState(false);
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState("");
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [residence, setResidence] = useState("");
  const [errorResidence, setErrorResidence] = useState("");
  const [description, setDescription] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [errorState, setErrorState] = useState("");
  const [submit, setSubmit] = useState("submit");
  const [state, setState] = useState({
    website: { status: true, value: "website" },
    android: { status: false, value: "Android app" },
    ios: { status: false, value: "IOS app" },
    desktop: { status: false, value: "Desktop app" },
    branding: { status: false, value: "Product Branding" },
    design: { status: false, value: "Product Design only" },
  });

  const handleChangeWebsite = (event) => {
    setState((prevState) => ({
      ...prevState,
      website: {
        status: event.target.checked,
        value: "Website",
      },
    }));
  };

  const handleChangeMobile = (event) => {
    setState((prevState) => ({
      ...prevState,
      android: {
        status: event.target.checked,
        value: "Android app",
      },
    }));
  };

  const handleChangeIos = (event) => {
    setState((prevState) => ({
      ...prevState,
      ios: {
        status: event.target.checked,
        value: "IOS app",
      },
    }));
  };
  const handleChangeDesktop = (event) => {
    setState((prevState) => ({
      ...prevState,
      desktop: {
        status: event.target.checked,
        value: "Desktop app",
      },
    }));
  };
  const handleChangeBranding = (event) => {
    setState((prevState) => ({
      ...prevState,
      branding: {
        status: event.target.checked,
        value: "Product Branding",
      },
    }));
  };
  const handleChangeDesign = (event) => {
    setState((prevState) => ({
      ...prevState,
      design: {
        status: event.target.checked,
        value: "Product Design",
      },
    }));
  };

  const { website, android, ios, desktop, branding, design } = state;

  var phoneValidator = new RegExp(/^[0-9\b]+$/);
  var emailValidator = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const send = async () => {
    const packages = [
      state.website,
      state.android,
      state.ios,
      state.desktop,
      state.branding,
      state.design,
    ];
    try {
      const data = qs.stringify({
        name,
        email,
        phone,
        residence,
        description,
        packages: packages
          .filter((selectedPackage) => selectedPackage.status === true)
          .map((pickedPackage) => {
            console.log(pickedPackage.value);
            return pickedPackage.value;
          }),
      });
      var config = {
        method: "post",
        url: "https://harvoxxtest.com.ng/build-harvoxx/build.php",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      const res = await axios(config);
      if (res.data.res === "success") {
        Swal.fire({
          title: "Thank you for reaching out to us!",
          text: "We will get back to you shortly",
          icon: "success",
          confirmButtonText: "Close",
          confirmButtonColor: "#DCA424",
        });
        setSubmit("Sent");
      } else {
        Swal.fire({
          title: "Something went wrong!",
          text: "Please check your internet connection and try again",
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#DCA424",
        });
        setSubmit("Try again");
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Something went wrong!",
        text: "Please check your internet connection and try again",
        icon: "error",
        confirmButtonText: "Close",
        confirmButtonColor: "#DCA424",
      });
      setSubmit("Try again");
    }
  };

  const validate = () => {
    if (name === "") {
      setErrorName("Please enter your full name");
      setErrorEmail("");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (email === "") {
      setErrorName("");
      setErrorEmail("Please enter your email address");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (!emailValidator.test(email)) {
      setErrorName("");
      setErrorEmail("Please enter a valid email address");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (phone === "") {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("Please enter your phone number");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (!phoneValidator.test(phone)) {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("Please enter only number");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (phone.length !== 11) {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("Phone number should be 11 characters");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (residence === "") {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("");
      setErrorResidence("Select your state of residence");
      setErrorState("");
      setErrorDescription("");
      return false;
    } else if (
      state.website.status === false &&
      state.android.status === false &&
      state.ios.status === false &&
      state.desktop.status === false &&
      state.branding.status === false &&
      state.design.status === false
    ) {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("Pick at least one option from above");
      setErrorDescription("");
      return false;
    } else if (description === "") {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription(
        "Enter a breif description of what you want to build"
      );
      return false;
    } else {
      setErrorName("");
      setErrorEmail("");
      setErrorPhone("");
      setErrorResidence("");
      setErrorState("");
      setErrorDescription("");
      setSubmit("loading ...");
      send();
    }
  };

  return (
    <>
      <Header />
      <div
        style={{ width: "100%", height: "auto", backgroundColor: "#fff3e4" }}
      >
        <Container>
          <Grid
            container
            style={{
              padding: "140px 0 80px 0",
              width: "100%",
              height: "auto",
              //   backgroundColor: "red",
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              style={{
                paddingRight: tablet ? null : "60px",
                paddingBottom: tablet ? "40px" : null,
              }}
            >
              <Typography
                variant="h3"
                color="primary"
                style={{ paddingBottom: "30px", fontSize: "54px" }}
                fontWeight={600}
              >
                Build{" "}
                <Typography
                  variant="h3"
                  style={{ fontSize: "54px" }}
                  fontWeight={600}
                  component="span"
                  color="secondary"
                >
                  with Harvoxx
                </Typography>
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "400" }}>
                Enjoy custom software development by our experienced and
                software engineers. Frontend, backend, database and server
                solutions for mobile apps, web applications and desktop.
              </Typography>
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  //   backgroundColor: "red",
                  margin: "30px 0",
                }}
              >
                <img
                  src={images.coder}
                  alt=""
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <div
                style={{ width: "100%", height: "auto", margin: "20px 0 0 0" }}
              >
                <Link
                  to={{ pathname: "https://wa.link/xdbene" }}
                  target="__blank"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      color: "#fff",
                      padding: "10px 25px",
                      borderRadius: "100px",
                    }}
                  >
                    Whatsapp
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "20px",
                    color: "#fff",
                    padding: "10px 25px",
                    borderRadius: "100px",
                  }}
                  onClick={() => setDisplay(true)}
                >
                  let's call
                </Button>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                elevation={0}
                style={{
                  height: "auto",
                  width: tablet ? "100%" : "100%",
                  backgroundColor: "#fff",
                  boxShadow: "0px 0px 35px rgba(0,0,0,0.1)",
                }}
              >
                <Container>
                  <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    style={{ padding: "20px 0" }}
                  >
                    Tell us about yourself
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        label="Full name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: "100%" }}
                      />
                      <Typography
                        variant="body2"
                        style={{ color: "red", marginTop: "8px" }}
                      >
                        {errorName}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        label="Email Address"
                        style={{ width: "100%" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Typography
                        variant="body2"
                        style={{ color: "red", marginTop: "8px" }}
                      >
                        {errorEmail}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextField
                        label="Phone Number"
                        style={{ width: "100%" }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <Typography
                        variant="body2"
                        style={{ color: "red", marginTop: "8px" }}
                      >
                        {errorPhone}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          State of Residence
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={residence}
                          label="State of Residence"
                          onChange={(e) => setResidence(e.target.value)}
                        >
                          {NaijaStates.states().map((state, idx) => (
                            <MenuItem key={idx} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography variant="body2" style={{ color: "red" }}>
                        {errorResidence}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
                <Container>
                  <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    style={{ padding: "20px 0" }}
                  >
                    Tell us about what you want to build
                  </Typography>
                  <FormControl component="fieldset" variant="outlined">
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={website.status}
                            onChange={handleChangeWebsite}
                            // name="website"
                          />
                        }
                        label="Website"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={android.status}
                            onChange={handleChangeMobile}
                            // name="android"
                          />
                        }
                        label="Android app"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ios.status}
                            onChange={handleChangeIos}
                            // name="ios"
                          />
                        }
                        label="IOS app"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={desktop.status}
                            onChange={handleChangeDesktop}
                            // name="ios"
                          />
                        }
                        label="Desktop app"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={branding.status}
                            onChange={handleChangeBranding}
                            // name="ios"
                          />
                        }
                        label="Product Branding"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={design.status}
                            onChange={handleChangeDesign}
                            // name="ios"
                          />
                        }
                        label="Product Design Only"
                      />
                    </FormGroup>
                    <Typography
                      variant="body2"
                      style={{ color: "red", marginTop: "8px" }}
                    >
                      {errorState}
                    </Typography>
                  </FormControl>
                  <TextField
                    fullWidth
                    style={{ margin: "30px 0 0px 0" }}
                    label="Brief Descripton of What You Want to Build"
                    id="outlined-multiline-flexible"
                    rows={5}
                    multiline
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Typography
                    variant="body2"
                    style={{ color: "red", marginTop: "8px" }}
                  >
                    {errorDescription}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={validate}
                      style={{ marginBottom: "30px" }}
                    >
                      {submit}
                    </Button>
                  </div>
                </Container>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Call show={display} onHide={setDisplay} />
      <Footer />
    </>
  );
}

export default Build;
