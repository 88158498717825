import React from "react";
import { Switch, Route } from "react-router-dom";
import Build from "./pages/Build";
import Homepage from "./pages/Homepage";
import { ScrollToTop } from 'react-router-scroll-to-top';

function App() {
  return (
    <>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={Homepage}/>
        <Route exact path="/build" component={Build}/>
      </Switch>
    </>
  );
}

export default App;
