import React, { useState } from "react";
import Modal from "react-modal";
import {
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import images from "../images/images";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
// import "sweetalert2/src/sweetalert2.scss";

Modal.setAppElement("#root");

function Call({ show, onHide }) {
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState("");
  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const tablet = useMediaQuery("(max-width:900px)");
  const mobile = useMediaQuery("(max-width:600px)");
  const customStyles = {
    overlay: {
      background: "rgba(0,0,0,0.5)",
      zIndex: 10,
      overflow: "auto",
      height: "100vh",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: mobile ? "90%" : tablet ? "70%" : "50%",
      padding: "0px 0 20px 0",
    },
  };

  var pattern = new RegExp(/^[0-9\b]+$/);

  const send = async () => {
    try {
      const data = qs.stringify({
        name: name,
        phone: phone,
      });
      var config = {
        method: "post",
        url: "https://harvoxxtest.com.ng/build-harvoxx/phone.php",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      const res = await axios(config);
      // console.log(res);
      // console.log(res.data);
      if (res.data.res === "success") {
        Swal.fire({
          title: "Thank you for reaching out to us!",
          text: "You will get a call from us shortly",
          icon: "success",
          confirmButtonText: "Close",
          confirmButtonColor: "#DCA424",
        });
      } else {
        Swal.fire({
          title: "Something went wrong!",
          text: "Please check your internet connection and try again",
          icon: "error",
          confirmButtonText: "Close",
          confirmButtonColor: "#DCA424",
        });
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Something went wrong!",
        text: "Please check your internet connection and try again",
        icon: "error",
        confirmButtonText: "Close",
        confirmButtonColor: "#DCA424",
      });
    }
  };

  const validate = () => {
    if (name === "") {
      setErrorName("Please enter your name");
      setErrorPhone("");
    } else if (phone === "") {
      setErrorName("");
      setErrorPhone("Please enter your phone number");
    } else if (!pattern.test(phone)) {
      setErrorName("");
      setErrorPhone("Please enter only numbers");
    } else if (phone.length !== 11) {
      setErrorName("");
      setErrorPhone("Phone number should be 11 character long");
    } else {
      setErrorName("");
      setErrorPhone("");
      onHide(false);
      send();
    }
  };

  return (
    <>
      <Modal
        isOpen={show}
        onAfterOpen={() => onHide(true)}
        onRequestClose={() => onHide(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            borderBottom: "1px solid #ccc",
            padding: "15px 0",
          }}
        >
          <Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Let's Give You a Call</Typography>
            <IconButton onClick={() => onHide(false)}>
              <CloseIcon />
            </IconButton>
          </Container>
        </div>
        <Container>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "auto",
            }}
          >
            <img
              src={images.callModal}
              alt=""
              style={{
                width: mobile ? "100%" : "auto",
                height: tablet ? "160px" : "250px",
                objectFit: "contain",
              }}
            />
          </div>
          <div style={{ margin: "10px 0 20px 0" }}>
            <TextField
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: "100%" }}
              label="Full Name"
            />
            <Typography
              variant="body2"
              style={{ color: "red", paddingTop: "5px" }}
            >
              {errorName}
            </Typography>
          </div>
          <div style={{ margin: "0px 0 20px 0" }}>
            <TextField
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: "100%" }}
              label="Phone Number"
            />
            <Typography
              variant="body2"
              style={{ color: "red", paddingTop: "5px" }}
            >
              {errorPhone}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ color: "#fff" }}
              onClick={() => onHide(false)}
            >
              close
            </Button>
            <Button
              variant="contained"
              style={{ color: "#fff" }}
              onClick={validate}
            >
              submit
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  );
}

export default Call;
